/* Overwrites bootstrap container. */
@media (min-width:1200px) {
  .container {
    width:1170px;
  }
}

.article-image {
  max-height: 433px;
}

.project img {
  max-height: 208px;
}

/*  My profile notification
-----------------------------------------------------------  */
.alert {
  margin-top: 15px;
  margin-bottom: 15px;
}

/*  Page preloader
-----------------------------------------------------------  */
#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}
#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
        -webkit-animation:spin 4s linear infinite;
        -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
    z-index: 3002;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

#loader-wrapper .loader-section {
    position: fixed;
    top: 0;
    width: 51%;
    height: 100%;
    background: #221918;
    z-index: 1000;
    -webkit-transform: translateX(0);  /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateX(0);  /* IE 9 */
    transform: translateX(0);  /* Firefox 16+, IE 10+, Opera */
}



    #loader-wrapper .loader-section.section-left {
        left: 0;
    }

    #loader-wrapper .loader-section.section-right {
        right: 0;
    }

    /* Loaded */
    .loaded #loader-wrapper .loader-section.section-left {
        -webkit-transform: translateX(-100%);  /* Chrome, Opera 15+, Safari 3.1+ */
            -ms-transform: translateX(-100%);  /* IE 9 */
                transform: translateX(-100%);  /* Firefox 16+, IE 10+, Opera */

        -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
                transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
    }

    .loaded #loader-wrapper .loader-section.section-right {
        -webkit-transform: translateX(100%);  /* Chrome, Opera 15+, Safari 3.1+ */
            -ms-transform: translateX(100%);  /* IE 9 */
                transform: translateX(100%);  /* Firefox 16+, IE 10+, Opera */

-webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
        transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
    }

    .loaded #loader {
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
                transition: all 0.3s ease-out;
    }
    .loaded #loader-wrapper {
        visibility: hidden;

        -webkit-transform: translateY(-100%);  /* Chrome, Opera 15+, Safari 3.1+ */
            -ms-transform: translateY(-100%);  /* IE 9 */
                transform: translateY(-100%);  /* Firefox 16+, IE 10+, Opera */

        -webkit-transition: all 0.3s 1s ease-out;
                transition: all 0.3s 1s ease-out;
    }

    /* JavaScript Turned Off */
    .no-js #loader-wrapper {
        display: none;
    }
    .no-js h1 {
        color: #222222;
    }

/*  Profiles search Styles
-----------------------------------------------------------  */
.pen-container {
  margin: 20px auto 0;
  max-width: 1200px;
}

.pen-content {
  box-shadow: 0 0 1px #d3d3d3;
  margin-bottom: 30px;
  padding: 20px;
}

.pen-footer {
  text-align: center;
}

.dbg-logo {
  display: inline-block;
}

body {
  font-family: "Arial", sans-serif;
  font-size: 14px;
}

.radio-wrapper {
  position: relative;
  margin-top: 5px;
  margin-right: 7em;
  margin-bottom: 20px;
  text-align: right;
}
.radio-wrapper label {
  cursor: pointer;
  display: inline-block;
  height: 22px;
  line-height: 22px;
  padding-left: 32px;
  position: relative;
}
.radio-wrapper input[type="radio"] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.radio-wrapper input[type="radio"] ~ .fake-box {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}
.radio-wrapper input[type="radio"] ~ .fake-box:after {
  background: #333;
  border-radius: 50%;
  content: '';
  height: 12px;
  left: 50%;
  margin-left: -6px;
  margin-top: -6px;
  position: absolute;
  top: 50%;
  visibility: hidden;
  width: 12px;
}
.radio-wrapper input[type="radio"]:checked ~ .fake-box:after {
  visibility: visible;
}
.radio-wrapper input[type="radio"]:focus ~ .fake-box {
  box-shadow: 0 0 8px #ccc;
}
.radio-wrapper input[type="radio"]:disabled ~ .radio-text,
.radio-wrapper input[type="radio"]:disabled ~ .fake-box {
  opacity: .5;
  cursor: default;
}

.field {
    display:flex;
    position:realtive;
    margin: 0 auto;
    margin-top:5em;
    width:80%;
}

.field>input[type=text],
.field>button {
    display:block;
    font:1.2em Myriad Pro;
    box-shadow: 0 1px 2px rgba(0, 0, 0,.1);
    outline: none;
}

.field>input[type=text] {
    flex:1;
    padding:0.6em;
    border:0.2em ;
}

.field>button {
    padding:0.6em 0.8em;
    background-color:#D91B5C;
    color:white;
    border:none;
}
.field>button:hover {
    cursor:pointer;
}

  .profiles-result {
    margin-bottom: 20px;
  }
  .profiles-result h1, h2, h3, h4, h5, h6, p {
      margin: 0;
      padding: 0;
  }

  .profiles-result h1, h2, h3, h4, h5, h6 {
      font-weight: 600;
  }

  .profiles-result button {
      text-decoration: none;
      color: #ff7659;
  }
  .profiles-result button {
      display: inline-block;
      background: #D91B5C;
      color: #FFF;
      padding: 5px 18px 5px 18px;
      border-radius: 15px;
      font-size: 0.7em;
      font-weight: 600;
      -webkit-transition: background 0.2s linear, -webkit-transform 0.2s cubic-bezier(0.63, 0.15, 0.03, 1.32);
      transition: background 0.2s linear, -webkit-transform 0.2s cubic-bezier(0.63, 0.15, 0.03, 1.32);
      transition: transform 0.2s cubic-bezier(0.63, 0.15, 0.03, 1.32), background 0.2s linear;
      transition: transform 0.2s cubic-bezier(0.63, 0.15, 0.03, 1.32), background 0.2s linear, -webkit-transform 0.2s cubic-bezier(0.63, 0.15, 0.03, 1.32);
      -webkit-transform: scale(1);
              transform: scale(1);
  }
  .profiles-result button:hover {
      background: #EC8DAD;
      -webkit-transform: scale(1.06);
              transform: scale(1.06);
  }

  .profiles-result header.hero {
      margin: 0 0 40px 0;
  }

  @-webkit-keyframes scale-in {
    0% {
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes scale-in {
    0% {
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  section.profile {
      -webkit-animation: scale-in 0.3s cubic-bezier(0.63, 0.15, 0.03, 1.52) forwards;
              animation: scale-in 0.3s cubic-bezier(0.63, 0.15, 0.03, 1.52) forwards;
      padding: 2px;
      background: #FFF;
      box-shadow: 4px 2px 20px #888;
      width: 220px;
      margin-top: 15px;
      overflow: hidden;
  }
  section.profile header.user {
      text-align: center;
      height: 210px;
  }
  section.profile header.user img {
      border-radius: 50%;
      margin: 15px 0 2px;
      height: 100px;
      width: 100px;
      -webkit-filter: none;
              filter: none;
      -webkit-transition: all 0.2s cubic-bezier(0.63, 0.15, 0.03, 1);
      transition: all 0.2s cubic-bezier(0.63, 0.15, 0.03, 1);
  }
section.profile header.user img:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
}
section.profile header.user h1 {
    font-size: 1.1em;
}
section.profile header.user p {
    font-size: 0.7em;
    color: #b0b0b0;
    margin: 0 0 10px;
}
section.profile header.user aside.loves {
    float: left;
    width: 45px;
    height: 100%;
    background: #f6f6f6;
    padding: 10px 0;
}
section.profile header.user aside.loves i.fa-heart {
  font-size: 1.3em;
  color: #ff7659;
  padding: 6px;
  border-radius: 50%;
  background: #FFF;
  margin: 0 0 2px;
  cursor: pointer;
  -webkit-transition: all 0.2s cubic-bezier(0.63, 0.15, 0.03, 1.52);
  transition: all 0.2s cubic-bezier(0.63, 0.15, 0.03, 1.52);
}
section.profile header.user aside.loves i.fa-heart:hover {
  color: #ff3b12;
  background: #f6f6f6;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
section.profile header.user aside.loves span.count {
  display: inline;
  margin: 0 0 20px;
  font-size: 0.6em;
  background: #FFF;
  border-radius: 10px;
  padding: 1px 5px;
}
section.profile ul.menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
section.profile ul.menu li.menu-item {
  height: 45px;
  background: #f6f6f6;
  -webkit-transition: all 0.2s cubic-bezier(0.63, 0.15, 0.03, 1.22);
  transition: all 0.2s cubic-bezier(0.63, 0.15, 0.03, 1.22);
}
section.profile ul.menu li.menu-item i.fa {
  float: left;
  width: 45px;
  height: 100%;
  text-align: center;
  background: #efefef;
  padding: 14px 0;
  color: #d2d2d2;
  font-size: 1.1em;
}
section.profile ul.menu li.menu-item a {
  display: block;
  font-size: 0.8em;
  color: #b2b2b2;
  padding: 14px 15px 14px 60px;
}
section.profile ul.menu li.menu-item.active {
  background: #f3f3f3;
}
section.profile ul.menu li.menu-item.active a {
  color: #787878;
}
section.profile ul.menu li.menu-item.active i.fa {
  background: #f0f0f0;
  color: #787878;
}
section.profile ul.menu li.menu-item:hover {
  margin-left: -45px;
}
section.profile ul.menu li.menu-item:hover i.fa {
  border-right: 2px solid #ff7659;
}
section.profile ul.menu li.menu-item:nth-of-type(2n) {
  background: #FFF;
}
section.profile ul.menu li.menu-item:nth-of-type(2n) i.fa {
  background: #f6f6f6;
}

.blog-nav-title h1 {
  font-size: 21px;
  line-height: 50px;
}

/* Banner area 1
-----------------------------------------------------------  */
.banner-wrapper {
    overflow: hidden;
}
    .banner-img {
        width: 100%;
        height: auto;
    }
/*  Nivo Slider
-----------------------------------------------------------  */
.nivo-slider {width:100%;overflow:hidden;position:relative;}
.nivo-slider .nivo-caption {padding: 20px 10px;width: 940px;left: 0;right: 0;position: absolute;z-index: 8;margin: 0 auto;}
.nivo-slider .nivo-directionNav a {color: #ff9311;font-size: 72px;top:50%;margin-top: -62px;text-shadow: 0 1px 2px rgba(0,0,0,0.3);}



/*  Master Slider
-----------------------------------------------------------  */
.ms-wrapp {position: relative !important;}



/*  Placeholder
-----------------------------------------------------------  */
.autocomplete-suggestions {background: none repeat scroll 0 0 #FFFFFF;box-shadow: 0 1px 2px rgba(0, 0, 0,.1);overflow: auto;margin-top: 2px;}
.autocomplete-suggestion { padding: 8px 12px;white-space: nowrap;overflow: hidden;color: #9d9d9d;}
.autocomplete-selected { background: #F0F0F0; }
.autocomplete-suggestions strong { font-weight: bold; color: #221918; }



/*  Auto Complete
-----------------------------------------------------------  */
.placeholder {color: #c8c8c8;}



/*  Isoptope
-----------------------------------------------------------  */
.isotope,
.isotope .isotope-item {
  -webkit-transition-duration: 1.2s;
     -moz-transition-duration: 1.2s;
      -ms-transition-duration: 1.2s;
       -o-transition-duration: 1.2s;
          transition-duration: 1.2s;
}

.isotope {
  -webkit-transition-property: height, width;
     -moz-transition-property: height, width;
      -ms-transition-property: height, width;
       -o-transition-property: height, width;
          transition-property: height, width;
}

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
     -moz-transition-property:    -moz-transform, opacity;
      -ms-transition-property:     -ms-transform, opacity;
       -o-transition-property:      -o-transform, opacity;
          transition-property:         transform, opacity;
}

.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  -webkit-transition-duration: 0s;
     -moz-transition-duration: 0s;
      -ms-transition-duration: 0s;
       -o-transition-duration: 0s;
          transition-duration: 0s;
}



/*  Media Element Player
-----------------------------------------------------------  */
.mejs-controls .mejs-volume-button .mejs-volume-slider .mejs-volume-current {
    position: absolute !important;
    left: 12px !important;
    bottom: 0 !important;
    width: 8px !important;
    height: 62px;
    background: none repeat scroll 0 0 #ff9311;
    border-radius: 2px !important;
    margin: 0 !important;
}
.mejs-video .mejs-controls {
    background: none repeat scroll 0 0 #ff9311;
}



/*  Twitter
-----------------------------------------------------------  */
#twitter .user img {display: none;}
#twitter .user span[data-scribe="element:name"] {display: none;}
#twitter .interact {text-align: right;}